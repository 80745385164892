import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-shared-range-selector",
  templateUrl: "./shared-range-selector.component.html",
  styleUrls: ["./shared-range-selector.component.scss"],
})
export class SharedRangeSelectorComponent implements OnInit {
  @Input() start_month: string;
  @Input() end_month: string;
  @Input() selectedYear: string;
  @Input() selectedQuarterValue: string;
  @Input() selectedRollingOption: string;

  @Input() dateError = false;
  @Input() dateRequired = false;

  rollingOptionsArray = [];
  quaterArray = [];
  yearArray = [];

  @Input() rollingRadioBtnSelected = false;
  @Input() showDateRangeRadioButton;
  @Input() yearRadioBtnSelected = false;
  @Input() quaterRadioBtnSelected = false;

  @Output() selected_start_date = new EventEmitter<string>();
  @Output() selected_end_date = new EventEmitter<string>();
  @Output() updatedYearValue = new EventEmitter<string>();
  @Output() updatedquaterValue = new EventEmitter<string>();
  @Output() updatedRollingValue = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    this.yearArray = this.getLabelValues(this.getYears());
    this.quaterArray = this.getLabelValues(this.getQuarters());
    this.rollingOptionsArray = this.getLabelValues(this.getRolling());
    console.log("rolling button", this.rollingRadioBtnSelected);
  }

  getLabelValues(array: string[] = []): { label: string; value: string }[] {
    return array.map((element) => ({ label: element, value: element }));
  }

  getRolling(): string[] {
    return [
      "Current minus 1 months",
      "Current plus 0 months",
      "Current plus 1 months",
      "Current plus 2 months",
      "Current plus 3 months",
      "Current plus 4 months",
      "Current plus 5 months",
      "Current plus 6 months",
      "Current plus 7 months",
      "Current plus 8 months",
      "Current plus 9 months",
      "Current plus 10 months",
      "Current plus 11 months",
    ];
  }

  getYears(): string[] {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let i = 5; i > 0; i--) {
      years.push(currentYear + i);
    }
    years.push(currentYear);
    for (let i = 10; i > 0; i--) {
      years.push(years[years.length - 1] - 1);
    }
    return years.map((year) => year.toString());
  }

  getQuarters(): string[] {
    return ["Q1", "Q2", "Q3", "Q4"];
  }

  radioBtnSelected(radioType: string): void {
    switch (radioType) {
      case "rolling":
        this.rollingRadioBtnSelected = !this.rollingRadioBtnSelected;
        if (this.rollingRadioBtnSelected) {
          this.updatedRollingValue.emit(this.selectedRollingOption);
          this.yearRadioBtnSelected = false;
          this.quaterRadioBtnSelected = false;
          this.showDateRangeRadioButton = false;
        } else {
          this.rollingRadioBtnSelected = true;
        }
        break;
      case "year":
        this.yearRadioBtnSelected = !this.yearRadioBtnSelected;
        if (this.yearRadioBtnSelected) {
          this.rollingRadioBtnSelected = false;
          this.quaterRadioBtnSelected = false;
          this.showDateRangeRadioButton = false;
        } else {
          this.rollingRadioBtnSelected = true;
        }
        break;
      case "quarter":
        this.quaterRadioBtnSelected = !this.quaterRadioBtnSelected;
        if (this.quaterRadioBtnSelected) {
          this.rollingRadioBtnSelected = false;
          this.yearRadioBtnSelected = false;
          this.showDateRangeRadioButton = false;
        } else {
          this.rollingRadioBtnSelected = true;
        }
        break;
      case "custom":
        this.showDateRangeRadioButton = !this.showDateRangeRadioButton;
        if (this.showDateRangeRadioButton) {
          this.rollingRadioBtnSelected = false;
          this.yearRadioBtnSelected = false;
          this.quaterRadioBtnSelected = false;
        } else {
          this.rollingRadioBtnSelected = true;
        }
        break;
    }
  }

  clearOtherDropdowns(dropdownName: string): void {
    if (dropdownName !== "rollingOption") {
      this.selectedRollingOption = null;
      this.updatedRollingValue.emit(null);
    } else {
      this.updatedRollingValue.emit(this.selectedRollingOption);
    }
    if (dropdownName !== "year") {
      this.selectedYear = null;
      this.updatedYearValue.emit(null);
    } else {
      this.updatedYearValue.emit(this.selectedYear);
    }
    if (dropdownName !== "quarter") {
      this.selectedQuarterValue = null;
      this.updatedquaterValue.emit(null);
    } else {
      this.updatedquaterValue.emit(this.selectedQuarterValue);
    }
    if (dropdownName === "satrt_month") {
      this.selected_start_date.emit(this.start_month);
    }
    if (dropdownName === "end_month") {
      this.selected_end_date.emit(this.end_month);
    }
  }
}
