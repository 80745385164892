<div id="booktimoff" *isFetchingData="loading$">
  <form
    class="form"
    novalidate="novalidate"
    id="create_exception_form"
    [formGroup]="createWorkExceptionForm"
    autocomplete="off"
    (ngSubmit)="onSave()"
  >
    <div id="client-detail ">
      <div>
        <div class="row">
          <div class="col-12 pr-md-5">
            <div class="form-group first">
              <label class="form-label">Work Exception Name</label>
              <input
                type="text"
                class="form-control custom space"
                placeholder="e.g. New Year's Day"
                formControlName="description"
              />
              <app-form-error
                [validation]="'required'"
                [form]="createWorkExceptionForm"
                [controlName]="'description'"
                [fieldLabel]="'Work Exception Name'"
              ></app-form-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pr-md-5">
            <span *ngIf="isEdit">
              <div class="form-group pb-0">
                <label class="form-label">Date</label>
              </div>
              <div class="form-group pt-0 range-calender">
                <p-calendar
                  appendTo="body"
                  placeholder="Select Date"
                  class="form-control custom"
                  formControlName="date"
                  [showIcon]="true"
                  inputId="date"
                ></p-calendar>
                <app-form-error
                  [validation]="'required'"
                  [form]="createWorkExceptionForm"
                  [controlName]="'date'"
                  [fieldLabel]="'Date'"
                ></app-form-error>
              </div>
            </span>
            <span *ngIf="!isEdit">
              <div class="row">
                <div class="col-sm-6 col-12">
                  <div class="form-group pb-0">
                    <label class="form-label">Start Date</label>
                  </div>
                  <div class="form-group pt-0 range-calender">
                    <div class="form-group pt-0 range-calender">
                      <p-calendar
                        appendTo="body"
                        placeholder="Select Date"
                        class="form-control custom"
                        formControlName="start_date"
                        [showIcon]="true"
                        inputId="date"
                        (onSelect)="dateSelected()"
                      ></p-calendar>
                      <app-form-error
                        [validation]="'required'"
                        [form]="createWorkExceptionForm"
                        [controlName]="'start_date'"
                        [fieldLabel]="'Start date'"
                      ></app-form-error>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-12">
                  <div class="form-group pb-0">
                    <label class="form-label">End Date</label>
                  </div>
                  <div class="form-group pt-0 range-calender">
                    <p-calendar
                      appendTo="body"
                      placeholder="Select Date"
                      class="form-control custom"
                      formControlName="end_date"
                      [showIcon]="true"
                      inputId="date"
                      (onSelect)="dateSelected()"
                    ></p-calendar>
                    <app-form-error
                      [validation]="'required'"
                      [form]="createWorkExceptionForm"
                      [controlName]="'end_date'"
                      [fieldLabel]="'End date'"
                    ></app-form-error>
                  </div>
                </div>
              </div>
              <div class="form-group pb-0" *ngIf="dateError">
                <small class="form-text text-danger">
                  Start Date should be less than End Date</small
                >
              </div>
            </span>
          </div>
        </div>
        <div class="row pr-md-4">

          <div class="col-12">
            <div class="form-group first dropdown">
              <label class="form-label">Exception Type</label>
              <p-dropdown
                [options]="exceptionTypes"
                formControlName="work_exception_type_id"
                placeholder="Select"
                [style]="{ 'width': '100%' }"
                optionLabel="label"
                optionValue="value"
                [scrollHeight]="'100px'"
                appendTo="body"
              >
              </p-dropdown>
              <app-form-error
                [validation]="'required'"
                [form]="createWorkExceptionForm"
                [controlName]="'work_exception_type_id'"
                [fieldLabel]="'Exception Type'"
              >
              </app-form-error>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group first">
              <label class="form-label">Hours</label>
              <input
                sflIsNumber
                type="text"
                class="form-control custom range-width space"
                placeholder="8"
                formControlName="hours"
              />
              <app-form-error
                [validation]="'min'"
                [form]="createWorkExceptionForm"
                [controlName]="'hours'"
                [fieldLabel]="'It'"
              ></app-form-error>

              <p class="red-text" *ngIf="createWorkExceptionForm?.get('hours')?.hasError('max') && createWorkExceptionForm?.get('hours')?.touched">
                It can have max value of 24
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
