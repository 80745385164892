export enum TimesheetStatus {
  ALL='ALL',
  Draft='Draft',
  APPROVED= 'APPROVED',
  SUBMITTED='SUBMITTED',
  Rejected='REJECTED',
  NotSubMitted = 'Not Started'
}

export enum TimesheetButtonStatus {
  Draft='Draft',
  APPROVED= 'APPROVED',
  SUBMITTED='SUBMITTED',
  Not_Submitted = 'Not Submitted',
  REJECTED='REJECTED'
}
