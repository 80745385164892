export interface Employee {
  employee: {
    daily_billable_hours?: number;
    email?: string;
    employee_type?: any;
    end_date?: string;
    first_name?: string;
    hourly_cost?: string;
    id?: number;
    last_name?: string;
    region?: any;
    start_date?: string;
    utilization_target?: string;
    region_id?: number;
    employee_type_id?: number;
    extended_fields?: any;
    ext_role_position?: string;
    ext_travel?: string;
    position_types?: PositionType[];
    inactive?: boolean;
    tags?: any;
    work_exception_summary?: WorkExceptionSummay[];
  };
}

interface WorkExceptionSummay {
  month: number;
  work_exception_cost: string;
  work_exceptions?: WorkExceptionDetail[];
  year: number;
}

interface WorkExceptionDetail {
  work_exception: {
    date: string;
    description: string;
    employee_id: number;
    hourly_cost: string;
    hours: number;
    id: number;
    work_exception_type_id: number;
  };
}

export interface Job {
  job: {
    state: string;
    completed_at: string;
    attempted_at: string;
    inserted_at: string;
    args: {
      start_date: string;
      end_date: string;
    };
    scheduled_at: string;
  };
}

export interface EmployeeFinancial {
  employee_financials: EmployeeFinancialData[];
}

export interface EmployeeFinancialData {
  employee_financial: {
    daily_billable_hours?: number;
    deleted_date?: null | string;
    effective_end_date?: string | null;
    effective_start_date?: string | null;
    employee?: {
      first_name: string;
      id: number;
      last_name: string;
    };
    end_date?: string;
    hourly_cost?: string;
    id?: number;
    start_date?: string;
    utilization_target?: string;
  };
}

export interface EmployeeList {
  employees?: Employee[];
}
export interface JobList {
  jobs?: Job[];
}

export interface SaveFilter {
  is_shared?: boolean;
  resource?: string;
  query_string?: string;
  name?: string;
}

export interface QueryFilter {
  query_filter: {
    id: number;
    is_shared: boolean;
    query_string: string;
    ref: string;
    resource: string;
    user_id: number;
    name: string;
  };
}
export interface ISavedFilterList {
  data: {
    query_filters?: QueryFilter[];
  };
}
export interface ISavedFilter {
  data: QueryFilter;
}

export class IFilter {
  daily_billable_hours?: number;
  email_search?: string;
  employee_type_name?: string;
  name?: string;
  hourly_cost?: string;
  region_name?: string;
  start_date?: any;
  utilization_target?: string;
  ext_role_position?: string;
  first_name_search?: string;
  last_name_search?: string;
  date?: Date;
  description?: string;
  description_search?: string;
  type_name?: string;
  employee_first_name?: string;
  employee_last_name_search?: string;
  position_types?: string;
  limit?: number;
  offset?: number;
  order_by?: string;
  employee_active = true;
  employee_inactive = false;
  employee_status?: string;
  tags?: string;
  status?: string;
}

export interface QueryFilterParams {
  limit?: number;
  offset?: number;
  order_by?: string;
  daily_billable_hours?: number;
  email?: string;
  employee_type_name?: string;
  end_date?: string;
  name?: string;
  hourly_cost?: string;
  region_name?: string;
  start_date?: string;
  utilization_target?: string;
  employee_ids?: string;
  start_date_gte?: string;
  end_date_lt?: string;
  employee_status?: string;
}

export interface GlobalDetailTagsList {
  global_details: GlobalDetailTagging[];
}

export interface GlobalDetailTagging {
  global_detail?: {
    extended_fields?: { tag: Tags[] };
    name?: string;
    id?: number;
  };
}

export interface Tags {
  id?: number;
  name?: string;
  description?: string;
}

export interface GlobalDetailTagCategoryList {
  global_details: GlobalDetailTaggingCategory[];
}
export interface GlobalDetailExtendedFiledListResponse {
  global_details: GlobalDetailExtendedFiled[];
}

export interface ExtendedFields {
  extendArray: extendedOBJ[]; 
}
export interface GlobalDetailExtendedFiled {
  global_detail?: {
    extended_fields?: ExtendedFields | any;
    name?: string;
    id?: number;
  };
}

export interface GlobalDetailTaggingCategory {
  global_detail?: {
    extended_fields?: { tagCategory: TagCategory[] };
    name?: string;
    id?: number;
  };
}

export interface TagCategory {
  id?: string;
  name?: string;
  description?: string;
  subTagCategory?: SubCategory[];
  tags?: Tag[];
}

export interface GlobalDetailTags {
  global_details: GlobalDetailTags[];
}

export interface GlobalDetailTags {
  global_detail?: {
    extended_fields?: { tags: Tag[] };
    name?: string;
    id?: number;
  };
}

export interface Tag {
  id?: string;
  name?: string;
  description?: string;
  tagCategory?: string;
  subTagCategory?: string;
}
export interface extendedOBJ {
  id?: string;
  jsonData?: any
}

export interface GlobalDetailSubCategoryList {
  global_details: GlobalDetailSubCategory[];
}

export interface GlobalDetailSubCategory {
  global_detail?: {
    extended_fields?: { subCategory: SubCategory[] };
    name?: string;
    id?: number;
  };
}

export interface SubCategory {
  id?: string;
  name?: string;
  description?: string;
  parentCategoryId?: string;
  tags?: Tag[];
}

export interface GlobalDetail {
  global_detail?: {
    extended_fields?: { roles: Roles[] };
    name?: string;
    id?: number;
  };
}

export interface Roles {
  id?: number;
  description?: string;
  name?: string;
  permissions?: [];
  blockUpdateDelete: boolean;
}
export interface GlobalDetailList {
  global_details: GlobalDetail[];
}
export interface WorkExceptionList {
  work_exceptions?: NormalWorkException[];
  holidays?: HolidayWorkException[];
}

export interface NormalWorkException {
  work_exception?: WorkException;
}

export interface HolidayWorkException {
  holiday?: WorkException;
}

export interface WorkException {
  description?: string;
  employee?: {
    id?: number;
    name?: string;
    first_name?: string;
    last_name?: string;
  };
  hours?: number;
  id?: number;
  work_exception_type?: {
    id?: number;
    name?: string;
  };
  date?: string;
}

export interface WorkExceptionTypes {
  work_exception_types?: WorkExceptionType[];
}

export interface WorkExceptionType {
  work_exception_type?: {
    id?: number;
    name?: string;
  };
}

export enum WorkExceptionApplyTo {
  ALL = "all",
  SPECIFIC = "specific",
}

export interface PositionTypeList {
  position_types?: PositionType[];
}

export interface PositionType {
  position_type?: {
    id?: number;
    name?: string;
  };
}

export interface EmployeeIds {
  employee_ids: number[];
}

export interface GlobalDetailsResponseObj {
  global_detail?: GlobalDetail
}

export interface GlobalDetail {
  extended_fields?: any[]
  id?: number
  name: string
}
