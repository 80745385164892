/**
 * @sunflowerlab
 * @author Ashish Kumar
 */

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppConstants, APP_ROUTES } from '@shared/constants';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { PermissionModules } from '@shared/models/permission.enum';
import { ImageConstant } from '@shared/constants/imageconstant';

@Component({
  selector: 'app-sfl-base',
  template: ''
})
export class SflBaseComponent implements OnDestroy {
  subscriptionManager: Subscription = new Subscription();
  isSubmitting = false;
  appRoutes = APP_ROUTES;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  paginationConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    showFirstLastButtons: true
  }
  permissionModules = PermissionModules;
  appConstants = AppConstants;
  imageConst= ImageConstant;
  constructor() { }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(form: FormGroup, controlName: string, validationType: string): boolean {
    const control = form.controls[controlName];
    if (!control) {
      return false;
    }
    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  checkFormForValidation(form: FormGroup) {
    if (form.invalid) {
      form.markAllAsTouched();
      return true;
    }
  }

}
