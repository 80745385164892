import { ChangeDetectorRef, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthNoticeService } from '@auth/auth-notice/auth-notice.service';
import { AdministrationService } from '@entities/administration/administration.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { KtDialogService } from '@shared/services';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';
import { SflBaseComponent } from '../sfl-base/sfl-base.component';
import { ButtonParams } from '@shared/models';
import { WorkException, WorkExceptionApplyTo } from '@entities/administration/administration.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { QueryFilterParams } from '@entities/client/client.model';
import { UpdateWorkExceptionRequest } from '@entities/manage-people/mange-people.model';
import { AppConstants } from '@shared/constants';
import { AlertType } from '@shared/models/alert-type.enum';
import { TimesheetStatus } from '@shared/enum/mange-timesheet.enum';

@Component({
  selector: 'app-book-time-off',
  templateUrl: './book-time-off.component.html',
  styleUrls: ['./book-time-off.component.scss']
})
export class BookTimeOffComponent extends SflBaseComponent implements OnInit {
  @Output() closeModelSignal: EventEmitter<boolean> = new EventEmitter();
  @Input() employee_id: number;
  cardTitle = 'Add Work Exceptions';
  cardSubTitle = null;
  buttons: ButtonParams[] = [];
  statuses = [];
  employees = [];
  exceptionTypes = [];
  // Todo
  // @Input() exceptionTypes = [];
  createWorkExceptionForm: FormGroup;
  exceptionId: number;
  workException: WorkException;
  workExceptionApplyTo = WorkExceptionApplyTo;
  showEmployeeSelection = false;
  exceptionTypeFlag = false;
  employeeIdFlag = false;
  isEdit = false;
  dateError = false;
  exception: string;
  employee: any;


  constructor(private readonly administrationService: AdministrationService,
    private readonly cdf: ChangeDetectorRef,
    private readonly authNoticeService: AuthNoticeService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly datePipe: DatePipe,
    private readonly ktDialogService: KtDialogService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly router: Router) { super() }


  ngOnInit(): void {
    if (this.employee_id) {
      this.getExceptionTypes();
      this.initForm();
    }
  }

  getParams(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.exceptionId = params.exceptionId;
      if (this.exceptionId) {
        this.cardTitle = 'Edit Work Exception';
        this.isEdit = true;
        this.getWorkException();
      }
      this.setBtnParams();
    });
  }

  getWorkException(): void {
    this.loading$.next(true);
    this.subscriptionManager.add(this.administrationService.getWorkException(this.exceptionId).subscribe(res => {
      this.workException = res.data.work_exception;
      this.setExceptionForm();
      this.loading$.next(false);
    }, () => this.loading$.next(false)))
  }

  setExceptionForm(): void {
    const exception = this.workException;
    this.createWorkExceptionForm.controls['description'].setValue(exception.description);
    this.createWorkExceptionForm.controls['date'].setValue(moment.utc(new Date(exception.date)).format('l LT'));
    this.createWorkExceptionForm.controls['work_exception_type_id'].setValue(exception.work_exception_type.id);
    this.employee = exception.employee.first_name + " " + exception.employee.last_name;
    this.exception = exception.work_exception_type.name;
    this.createWorkExceptionForm.controls['hours'].setValue(exception.hours);
    this.createWorkExceptionForm.controls['employee_id'].setValue(exception.employee.id);
  }

  initForm(): void {
    this.createWorkExceptionForm = new FormGroup({
      'description': new FormControl('', Validators.required),
      'employee_id': new FormControl(this.employee_id, Validators.required),
      'work_exception_type_id': new FormControl('', Validators.required),
      'hours': new FormControl(8, [Validators.max(24), Validators.min(1)]),
      'start_date': new FormControl(null),
      'end_date': new FormControl(null),
    });
  }

  getEmployee(): void {
    const queryFilter: QueryFilterParams = {
      order_by: 'asc:first_name',
    }
    this.loading$.next(true);
    this.subscriptionManager.add(this.administrationService.getEmployeeList(queryFilter).subscribe(res => {
      if (res?.data?.employees) {
        const employees = res?.data?.employees;
        employees.forEach((employee) => {
          this.employees.push({
            label: employee?.employee?.first_name + ' ' + employee?.employee?.last_name,
            value: employee?.employee?.id,
          });
        });
      }
      this.loading$.next(false);
    }, () => this.loading$.next(false)))
  }

  getExceptionTypes(): void {
    this.loading$.next(true);
    this.subscriptionManager.add(this.administrationService.getExceptionTypes().subscribe(res => {
      if (res?.data?.work_exception_types) {
        const types = res?.data?.work_exception_types;
        types.forEach((type) => {
          this.exceptionTypes.push({
            label: type?.work_exception_type?.name,
            value: type?.work_exception_type?.id,
          });
        });
      }
      this.loading$.next(false);
    }, () => this.loading$.next(false)))
  }

  setExceptionType(exceptionType): void {
    console.log(exceptionType);
    this.exception = exceptionType?.label;
    this.createWorkExceptionForm.controls.work_exception_type_id.setValue(exceptionType?.value);
    this.cdf.detectChanges();
  }

  setEmployee(employee): void {
    this.employee = employee?.label;
    this.createWorkExceptionForm.controls.employee_id.setValue(employee);
    this.cdf.detectChanges();
  }

  setBtnParams(): void {
    this.buttons = [
      {
        btnClass: 'btn btn-cancel',
        btnText: 'Cancel',
        redirectPath: this.appRoutes.MANAGE_WORK_EXCEPTION,
      },
      {
        btnClass: 'btn-save',
        btnText: 'Save',
        btnType: 'submit',
        action: this.exceptionId ? this.onEdit.bind(this) : this.onSave.bind(this),
        loading: this.isSubmitting
      }
    ];
  }

  onEdit(): void {
    if (!this.checkFormForValidation(this.createWorkExceptionForm)) {
      this.isSubmitting = true;
      this.setBtnParams();
      const workExceptionObj: UpdateWorkExceptionRequest = this.getUpdatedObject(this.createWorkExceptionForm?.value);
      this.subscriptionManager.add(this.administrationService.updateWorkException(workExceptionObj, this.exceptionId).subscribe(res => {
        this.router.navigateByUrl(this.appRoutes.MANAGE_WORK_EXCEPTION);
        this.layoutUtilsService.showActionNotification('Work Exception updated successfully', AlertType.Success);
        this.isSubmitting = false;
        this.setBtnParams();
        this.workException = res.data.work_exception;
        this.exceptionId = this.workException.id;
      }, (err) => this.onError(err)));
    }
  }

  onSave(): void {
    if (!this.createWorkExceptionForm.value.work_exception_type_id || !this.createWorkExceptionForm.value.employee_id) {
      this.checkFormForValidation(this.createWorkExceptionForm)
      if (!this.createWorkExceptionForm.value.employee_id) {
        this.employeeIdFlag = true;
      }
      if (!this.createWorkExceptionForm.value.work_exception_type_id) {
        this.exceptionTypeFlag = true;
      }
    } else {
      if (!this.checkFormForValidation(this.createWorkExceptionForm)) {
        this.isSubmitting = true;
        this.setBtnParams();
        const workExceptionObj = this.createWorkExceptionForm.value;
        delete workExceptionObj.date;
        workExceptionObj.start_date = this.datePipe.transform(new Date(workExceptionObj.start_date), AppConstants.format);
        workExceptionObj.end_date = this.datePipe.transform(new Date(workExceptionObj.end_date), AppConstants.format);
        workExceptionObj.status = TimesheetStatus.SUBMITTED,
          this.subscriptionManager.add(this.administrationService.createWorkException(workExceptionObj).subscribe(res => {
            this.closeModel();
            this.layoutUtilsService.showActionNotification('Work Exception created successfully', AlertType.Success);
          }, (err) => this.onError(err)));
      }
    }
  }

  onSuccess(successMsg: string): void {
    this.authNoticeService.setNotice(successMsg, 'success');
  }

  onError(err): void {
    this.isSubmitting = false;
    this.setBtnParams();
    const error = err.error;
    this.authNoticeService.setNotice(error.errors, 'danger');
  }

  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.ktDialogService.hide();
  }

  exceptionTypeSelected(): void {
    this.exceptionTypeFlag = false;
  }

  employeeSelected(): void {
    this.employeeIdFlag = true;
  }

  dateSelected(): void {
    this.dateError = false;
    const workExceptionValues = this.createWorkExceptionForm.value;
    if (workExceptionValues.start_date && workExceptionValues.end_date) {
      if (workExceptionValues.start_date > workExceptionValues.end_date) {
        this.dateError = true;
      }
    }
  }

  getUpdatedObject(formData: UpdateWorkExceptionRequest): UpdateWorkExceptionRequest {
    return {
      date: this.datePipe.transform(new Date(formData?.date), AppConstants.format),
      description: formData?.description,
      hours: formData?.hours,
      employee_id: formData?.employee_id,
      work_exception_type_id: formData?.work_exception_type_id
    }
  }

  closeModel(): void {
    this.closeModelSignal.emit(true);
  }


  resetForm(): void {
    this.createWorkExceptionForm.reset();
    this.initForm();
  }

}
